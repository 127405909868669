.container {
  width: 640px;
  height: 640px;
  background-color: rgba(230, 230, 230, 0.9);
}

.pinned-app {
  width: 96px;
  height: 84px;
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.all-apps {
  font-size: 0.7em;
}

.pinned-app-text {
  font-size: 12px;
  margin-top: 4px;
}

.recommended-app-text {
  font-size: 12px;
  margin-left: 20px;
}

.startmenu-animation {
  transform: translateY(120%);
}
