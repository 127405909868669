.desktop-grid {
  grid-template-columns: repeat(auto-fill, 90px);
  grid-template-rows: repeat(auto-fill, 90px);
  padding-top: 6px;
  padding-left: 2px;
  gap: 18px 1px;
}

.desktop-text-shadow {
  text-shadow: 0 0 1px black, 0 0 2px black, 0 0 3px black, 0 0 4px black,
    0 1px 1px black, 0 1px 2px black;
}
